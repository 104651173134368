"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * quick fix for elements 
    // * that are set to invisible by default 
    // * and only become visible through javascript
    // *

    var bodyElement = document.body;
    bodyElement.classList.remove("js-disable");


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mainnav
    // *
    // *

    const hamburger = document.querySelector(".js-menu-hamburger");
    const aside = document.querySelector(".js-menu");
    const menuclose = document.querySelector(".js-menu-close");

    if (hamburger !== null) {
        hamburger.onclick = function(e) {
            hamburger.classList.toggle('open');
            aside.classList.toggle("open");  
        }
    }

    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            event.preventDefault();
            hamburger.classList.toggle('open');
            aside.classList.toggle("open");  
        }
    });

    document.onclick = function(e){
        if (e.target.classList.contains('js-menu-close')) {
            hamburger.classList.toggle('open');
            aside.classList.toggle("open");  
        }
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * subnav
    // *
    // *

    const nav = document.querySelectorAll(".js-subnav");
    
    if (nav !== null) {
        for (var i=0; i < nav.length; i++) {
            nav[i].onclick = function(e) {
                this.parentNode.parentNode.classList.toggle("active");
            }
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    if (imageSlider !== null) {
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    // Select the instagramSlider
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    // Check if imageSlider were found
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : false,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 2,
                perMove         : 1,
                speed           : 2000,
                interval        : 4000,
                gap             : '2rem',
                padding: { left: 0, right: '25%' },
                breakpoints: {
                    768: {
                        perMove : 1,
                        perPage: 2,
                    },
                    576: {
                        perMove : 1,
                        perPage : 1,
                        padding : '30px'
                    },
                }
            }).mount();
        }
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    const anchors = document.querySelectorAll(".js-scrollTo");

    for (const anchor of anchors) {
        anchor.addEventListener("click", clickHandler);
    }

    function clickHandler(e) {
        e.preventDefault();
    
        let href = this.getAttribute("href").split("#");
        let newAnchor = "#my" + href[1]
        let offsetTop = document.querySelector(newAnchor).offsetTop;

        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    window.addEventListener('load', function () {
        let getHash = window.location.hash;
        let elementExists = document.getElementById("my" + getHash.slice(1));

        if (getHash !== null && elementExists !== null) {
            let name = "#my" + getHash.slice(1);
            let offsetTop = document.querySelector(name).offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }

    }, false);


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for(let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];
            
            // check if link has same hostname. if not add target blank.
            currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
        }
    };
    
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 200,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * quickbar
    // *
    // *

    // Adding the resize event listener to the window
    window.addEventListener('resize', checkWidth);

    function checkWidth() {
        var windowWidth = window.innerWidth;

        if (windowWidth > 992) {
            // Selecting all elements with the class 'qelement' inside the 'quickbarwrapper' class
            var qElements = document.querySelectorAll('.js-quickbar-item');

            // Adding mouseover and mouseout event listeners to each element
            qElements.forEach(function(element) {
                element.addEventListener('mouseover', function() {
                    this.classList.add('active');
                });
                element.addEventListener('mouseout', function() {
                    this.classList.remove('active');
                });
            });
        }
    }
    // Call checkWidth initially when the script loads
    checkWidth();
});
